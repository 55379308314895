@media (min-width: 55px) and (max-width: 1023px) {
  .sign_btn_container {
    display: flex;
    justify-content: flex-start;
  }
}

@media (min-width: 0px) and (max-width: 480px) {
  .sign_up_btn {
    /* height: 1.8rem; */
    /* font-size: x-small; */
    display: flex;
    align-items: center;
  }
}
