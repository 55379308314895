@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"), url("./fonts/GothamBold.woff") format("woff"),
    url("./fonts/GothamBold.woff2") format("woff2"),
    url("./fonts/GothamBold.otf") format("otf");
  font-display: fallback;
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"), url("./fonts/GothamBook.woff") format("woff"),
    url("./fonts/GothamBook.woff2") format("woff2"),
    url("./fonts/GothamBook.otf") format("otf");
  font-display: fallback;
}

.GothamBold,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GothamBold";
}

.GothamBook,
p,
label {
  font-family: "GothamBook";
}

input,
textarea,
.Toastify__toast-body,
label {
  font-family: "GothamBook" !important;
}

.Toastify__toast {
  width: fit-content;
}

:root {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  max-width: 100%;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #fff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --toastify-color-success: #138086;
  --toastify-color-info: #138086;
}

:root,
[data-amplify-theme] {
  --amplify-colors-font-primary: #333;
  /* you can also use references: */
  --amplify-colors-font-secondary: var(--amplify-colors-neutral-60);
}
/* All components have a class name starting with `amplify` */
div [data-amplify-router-content] {
  background: none;
}

[data-amplify-authenticator] [data-amplify-container] {
  text-align: left;
}

input:focus {
  outline: none;
  box-shadow: none;
}

.auth .amplify-input,
.auth .amplify-select,
.auth
  .amplify-field-group__outer-end
  .amplify-field-group__control:not(:focus) {
  background: #fafafa;
  border-color: #ddd;
}

/* Hide labels on auth sign-in and sign-up */
.auth .amplify-label {
  display: none;
}

.auth .amplify-button--fullwidth,
.auth .amplify-button--primary {
  border-radius: 32px;
}

.auth [data-amplify-authenticator] [data-amplify-router] {
  background-color: none;
  border: none;
}

.auth [data-amplify-authenticator] [data-state="inactive"] {
  background: none;
  outline: none;
}

.auth .amplify-tabs[data-indicator-position="top"] > * {
  outline: none;
}

.active {
  color: #138086;
  border-radius: 5px;
}

a {
  font-weight: 500;
  color: #138086;
  text-decoration: inherit;
}

a:hover {
  color: #138086;
}

body {
  margin: 0;
  display: flex;
  min-width: 320px;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

h1.colledge {
  font-family: GothamBold;
  font-size: 64px;
  color: Black;
  line-height: 120%;
  text-transform: uppercase;
}

h1.colledge.white {
  color: white;
}

/* file upload button */
input[type="file"] {
  padding: 8px;
  display: flex;
  align-items: center;
  height: auto;
}

input[type="file"]::file-selector-button {
  border-radius: 32px;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  background-color: #138086;
  border: 1px solid #138086;
  text-align: center;
  margin-left: 2px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
}

input[type="file"]::file-selector-button:hover {
  color: #fff;
  background-color: #138086;
}

@media screen and (max-width: 620px) {
  h1.colledge {
    font-size: 32px;
  }
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

/* style.css */

#root {
  max-width: 100%;
  width: 100%;
}

.lg-container {
  max-width: 1440px;
  width: 100%;
  display: flex;
  background-color: white;
  justify-content: space-between;
}

.Hero.h-px {
  height: 800px;
}

/* App.css */

#root {
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

summary {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

/* the rest is just for esthetics */

summary::after {
  margin-left: 1ch;
  display: inline-block;
  content: url("assets/chevron.svg");
  transition: 0.2s;
  transform-origin: center center;
  width: 24px;
  height: 24px;
}

details[open] summary::after {
  transform: rotate(180deg);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.skeleton-block {
  background: linear-gradient(90deg, #c7c7c7 0px, #dfdfdf 40px, #c7c7c7 80px);
  background-size: 350px;
  animation: skeleton-loader-pulse 2s infinite;
}

@keyframes skeleton-loader-pulse {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 270px;
  }
}

@keyframes skeleton-loader-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.card-zoom-effect:hover {
  transition: ease-out 0.4s;
  transform: scale(1.07);
  cursor: pointer;
  background: #faebd7;
  font-weight: 600;
}

.custom-shape-divider-top-1700474728 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .custom-shape-divider-top-1700474728 svg,
  .custom-shape-divider-bottom-17004776156 svg {
    width: calc(131% + 1.3px);
    height: 75px;
  }
}

@media (max-width: 767px) {
  .custom-shape-divider-top-1700474728 svg,
  .custom-shape-divider-bottom-17004776156 svg {
    width: calc(138% + 1.3px);
    height: 64px;
  }
}

.custom-shape-divider-bottom-17004776156 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-17004776156 svg,
.custom-shape-divider-top-1700474728 svg {
  position: relative;
  display: block;
  width: calc(118% + 1.3px);
  height: 81px;
}

.custom-shape-divider-bottom-17004776156 .shape-fill,
.custom-shape-divider-top-1700474728 .shape-fill {
  fill: #ffffff;
}

.inputSearch:focus {
  border-bottom: teal;
  outline: teal;
}

.invite-button .invite-value {
  bottom: 111px;
}

.invite-button .invite-icon {
  bottom: 102px;
}

body .rc-slider-track,
body .rc-slider-tracks {
  background-color: teal;
}

body .rc-slider-handle {
  border-color: teal;
  background-color: teal;
}

body .rc-slider-handle:active {
  border-color: teal !important;
  box-shadow: 0 0 8px teal !important;
}
.rc-tooltip-content {
  min-width: 180px !important;
}

body #toolTipID {
  background: teal !important;
  min-width: 100px !important;
  width: 100% !important;
  font-size: 16px !important;
  text-align: center !important;
  position: relative !important;
  bottom: 2px !important;
  font-weight: 700 !important;
}

body .rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: teal !important;
  bottom: -45px !important;
  border-width: 8px 8px 0 !important;
  position: relative !important;
}

body .rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-width: 0 8px 8px !important;
  border-bottom-color: teal !important;
}

body .fileUpload[type="file"] {
  border: 2px solid transparent !important;
}

body .fileUpload[type="file"]:focus {
  box-shadow: none !important;
  border-color: rgb(156 163 175 / var(--tw-ring-opacity)) !important;
  outline: none !important;
}

.selector-main-div {
  padding-left: 12px;
}

@media (max-width: 1023px) {
  body .editFormYear .selector-main-div {
    padding-left: -12px !important;
  }
}
body .borderFocus {
  border: 2px solid #9ca3af !important;
}

body .SearchBorderFocus {
  border: 1px solid #9ca3af !important;
}

@media (max-width: 768px) and (min-width: 425px) {
  .selectInptuField {
    /* max-width: 185px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 424px) and (min-width: 300px) {
  .selectInptuField {
    /* max-width: 150px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.profileImageMain img {
  width: 100% !important;
  height: 100% !important;
}
